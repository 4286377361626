import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Divider,
  InputNumber,
  DatePicker,
} from 'antd'
import {
  ArrowDownOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import Import from '../../components/Import'
import moment from 'moment'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function FamilyBatchEditExcelModal (props) {
  const metadata = useSelector(state => state.metadata.value)
  const userData = useSelector(state => state.user.value)
  const {visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [services, setServices] = useState(null)
  const [serviceOptions, setServiceOptions] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      try {

      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    console.dir(fileList)
    console.dir(values)
    try {
        onOk()
        setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }
      const errorMessage = err.message || message.error(I18n.t('errorMessageUnknownError'))
      message.error(errorMessage)
    }
  }

  return (
      <Modal
          title={I18n.t('批量編輯家庭 (Excel)')}
          visible={visible}
          onCancel={onCancel}
          width="50%"
          maskClosable={false}
          footer={null}
      >
        {loaded ? (
            <Spin spinning={loading}>
              <Form
                  {...formLayout}
                  form={form}
                  initialValues={{
                    beginAt:moment()
                  }}
                  onFinish={onFinish}
              >
                <Form.Item
                    label={I18n.t('選擇文件')}
                    name="file"
                >
                  <Import
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      action={`${metadata.cloudHost}family/batch/excel`}
                      listType="text"
                      maxCount={1}
                      fileList={fileList}
                      setFileList={setFileList}
                      buttonText="上傳文件 (*.xlsx;*.xls)"
                  />
                </Form.Item>
                <Form.Item
                    label={<span></span>}
                    colon={false}
                    name="download"
                >
                  <Button icon={<ArrowDownOutlined />} href="/family-template.xlsx">{'下載模板'}</Button>
                </Form.Item>
                <Form.Item {...formTailLayout}>
                  <Button type="primary" htmlType="submit" block>
                    {I18n.t('提交')}
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
        ) : <SpinFull/>}
      </Modal>
  )
};