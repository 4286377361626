import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import moment from 'moment';

import {Dropdown, Menu, Button, Modal, message, PageHeader, Tooltip, Image, Tag, Table} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UploadOutlined, SyncOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusCircleOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import * as ToolPermission from '../../tools/ToolPermission'

import FamilyEditModal from './FamilyEditModal'
import FamilyBatchEditModal from './FamilyBatchEditModal'
import FamilyBatchEditExcelModal from './FamilyBatchEditExcelModal'
import FamilyAddModal from './FamilyAddModal'
import MemberViewModal from './MemberViewModal'
import QrCodeViewModal from './QrCodeViewModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import FamilyServiceViewModal from './FamilyServiceViewModal'
import FamilyImportModal from './FamilyImportModal'

export default function FamilyViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value);
  const userPermissionsData = useSelector(state => state.userPermissions.value);
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [familyEditModal, setFamilyEditModal] = useState(null)
  const [familyBatchEditModal, setFamilyBatchEditModal] = useState(null)
  const [familyBatchEditExcelModal, setFamilyBatchEditExcelModal] = useState(null)
  const [familyAddModal, setFamilyAddModal] = useState(null)
  const [memberViewModal, setMemberViewModal] = useState(null)
  const [familyImportModal, setFamilyImportModal] = useState(null)
  const [regions, setRegions] = useState([])
  const [services, setServices] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [serviceOptions, setServiceOptions] = useState([])
  const [familyServiceViewModal, setFamilyServiceViewModal] = useState(null)
  const [qrCodeViewModal, setQrcodeViewModal] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      const [
          regionRes,
        serviceRes,
      ] = await Promise.all([
        ToolRequest.request('GET', '/region'),
        ToolRequest.request('GET', '/service',{limit:5000}),
      ])
      setRegions(regionRes.data)
      const regionOptions = regionRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setRegionOptions(regionOptions)
      const serviceOptions = serviceRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setServices(serviceRes.data)
      setServiceOptions(serviceOptions)
      setLoaded(true)
    })()
  }, [tableKey])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setFamilyEditModal({
                      visible: true,
                      familyId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE',
                              `/family/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                  case 'member': {
                    setMemberViewModal({
                      visible: true,
                      familyId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'family-service': {
                    setFamilyServiceViewModal({
                      visible: true,
                      familyId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'member-qr-code': {
                    setQrcodeViewModal({
                      visible: true,
                      familyId: record.id,
                      familyNumber: record.familyNumber,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    {I18n.t('編輯家庭')}
                  </Menu.Item>
                )}
                {(
                  ToolPermission.hasPermission(userPermissionsData, 'user', 'delete') &&<Menu.Item key="remove">
                    {I18n.t('刪除家庭')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="member">
                    {I18n.t('家庭成員')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="family-service">
                    {I18n.t('家庭服務')}
                  </Menu.Item>
                )}
                {(
                    <Menu.Item key="member-qr-code">
                      {I18n.t('會員二維碼')}
                    </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'familyNumber',
      title: I18n.t('familyNumber'),
      sorter:true,
    },
    {
      key: 'regionId',
      title: I18n.t('地區'),
      render: (value, record) => {
        const region = regions.find(item => item.id === value)
        if (region) {
          return region.name[userData.locale]
        }
      },
    },
    {
      key: 'familyServices',
      title: I18n.t('服務'),
      render: (value, record) => {
        const columns = [
          {
            title: '服务',
            dataIndex: 'service',
            key: 'service',
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (value, record) => {
                const beginAt = record.beginAt ? moment(record.beginAt).format('YYYY-MM-DD HH:mm:ss') : ''
                const endAt = record.endAt ? moment(record.endAt).format('YYYY-MM-DD HH:mm:ss') : ''
              return {
                ONGOING: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag></Tooltip>,
                WAITING: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag></Tooltip>,
                ENDED: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag></Tooltip>,
                TERMINATED: <Tooltip title={`開始時間：${beginAt} 結束時間: ${endAt}`} color="cyan" key={Math.random().toString()}><Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag></Tooltip>,
              }[value];
            },
          },
        ]
        const data = value.map(item=>{
          const service = services.find(service=>service.id === item.service_id)
          return {
            key:item.id,
            service:service ? service.name[userData.locale] : '',
            status:item.status,
            beginAt:item.begin_at ? moment(item.begin_at).format('YYYY-MM-DD HH:mm:ss') : '',
            endAt:item.end_at ? moment(item.end_at).format('YYYY-MM-DD HH:mm:ss') : '',
          }
        })
        return <Table columns={columns} dataSource={data} pagination={false} showHeader={false}/>
      },
    },
    {
      key: 'mainMemberId',
      sorter:true,
      title: I18n.t('申請人'),
      render: (value, record) => {
        return record.mainMember && `${record.mainMember.firstNameEn} ${record.mainMember.lastNameEn}`
      },
    },
    {
      key: 'applicantPhone',
      title: I18n.t('聯繫電話'),
      sorter:true,
    },
    {
      template: 'isEnabled',
      key: 'smsSentState',
      title: I18n.t('短訊發送狀態'),
      sorter:true,
    },
    {
      template: 'id',
      key: 'initPassword',
      title: '初始密碼',
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      title: I18n.t('啟用'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'regionId',
      title: I18n.t('地區'),
      options: regionOptions
    },
    {
      template: 'select',
      key: 'serviceId',
      title: I18n.t('服務'),
      options: serviceOptions
    },
    {
      template: 'select',
      key: 'serviceStatus',
      title: '服務狀態',
      defaultValue: 'ONGOING',
      options: [
        {
          label: <Tag icon={<SyncOutlined spin />}  color="processing">{I18n.t('進行中')}</Tag>,
          value: 'ONGOING'
        },
        {
          label: <Tag icon={<ClockCircleOutlined />} color="default">{I18n.t('等待中')}</Tag>,
          value: 'WAITING'
        },
        {
          label: <Tag icon={<CloseCircleOutlined />} color="error">{I18n.t('已結束')}</Tag>,
          value: 'ENDED'
        },
        {
          label: <Tag icon={<MinusCircleOutlined />} color="warning">{I18n.t('已中止')}</Tag>,
          value: 'TERMINATED'
        },
      ]
    },
    {
      template: 'select',
      key: 'isEnabled',
      title: I18n.t('啟用狀態'),
      defaultValue:true,
      options: [
        {
          label:'啟用',
          value:true,
        },
        {
          label:'停用',
          value:false,
        },
      ]
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      regionId: values.regionId,
      serviceId: values.serviceId,
      serviceStatus: values.serviceStatus,
      isEnabled: values.isEnabled,
    }
  }

  const loadDataSource = async (body) => {
    const familyRes = await ToolRequest.request('GET', '/family', {
      ...body,
    })
    console.dir(familyRes)
    return familyRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('家庭列表')}
      extra={[
        ToolPermission.hasPermission(userPermissionsData, 'family', 'post') &&(
            <Button
                type="primary"
                shape="round"
                onClick={()=>{
                  if(selectedRowKeys.length){
                    setFamilyBatchEditModal({
                      visible: true,
                      familyIds: selectedRowKeys,
                      key: Math.random().toString(),
                    })
                  }else {
                    message.error(I18n.t('請至少選擇一個家庭'))
                  }
                }}
                icon={<EditOutlined />}
            >
              批量編輯家庭 (界面)
            </Button>
        ),
        ToolPermission.hasPermission(userPermissionsData, 'family', 'post') &&(
            <Button
                type="primary"
                shape="round"
                onClick={()=>{
                  setFamilyBatchEditExcelModal({
                    visible: true,
                    key: Math.random().toString(),
                  })
                }}
                icon={<EditOutlined />}
            >
              批量編輯家庭成員 (Excel)
            </Button>
        ),
        ToolPermission.hasPermission(userPermissionsData, 'import', 'family') &&(
          <Button
            type="primary"
            shape="round"
            onClick={()=>{
              setFamilyImportModal({
                visible: true,
                key: Math.random().toString(),
              })
            }}
            icon={<UploadOutlined />}
          >
            批量導入家庭
          </Button>
        ),
        (ToolPermission.hasPermission(userPermissionsData, 'family', 'post') &&
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setFamilyAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        searchColumns={searchColumns}
        selectable="checkbox"
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      {familyEditModal && <FamilyEditModal
        key={familyEditModal.key}
        visible={familyEditModal.visible}
        familyId={familyEditModal.familyId}
        onOk={() => {
          setFamilyEditModal({
            ...familyEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setFamilyEditModal({
            ...familyEditModal,
            visible: false,
          })
        }}
      />}
      {familyBatchEditModal && <FamilyBatchEditModal
          key={familyBatchEditModal.key}
          visible={familyBatchEditModal.visible}
          familyIds={familyBatchEditModal.familyIds}
          onOk={() => {
            setFamilyBatchEditModal({
              ...familyBatchEditModal,
              visible: false,
            })
            setTableKey(Math.random().toString())
          }}
          onCancel={() => {
            setFamilyBatchEditModal({
              ...familyEditModal,
              visible: false,
            })
          }}
      />}
      {familyBatchEditExcelModal && <FamilyBatchEditExcelModal
          key={familyBatchEditExcelModal.key}
          visible={familyBatchEditExcelModal.visible}
          onOk={() => {
            setFamilyBatchEditExcelModal({
              ...familyBatchEditExcelModal,
              visible: false,
            })
            setTableKey(Math.random().toString())
          }}
          onCancel={() => {
            setFamilyBatchEditExcelModal({
              ...familyBatchEditExcelModal,
              visible: false,
            })
          }}
      />}
      {familyAddModal && <FamilyAddModal
        key={familyAddModal.key}
        visible={familyAddModal.visible}
        onOk={(familyId) => {
          setFamilyAddModal({
            ...familyAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
          setMemberViewModal({
            visible: true,
            familyId,
            key: Math.random().toString(),
          })
        }}
        onCancel={() => {
          setFamilyAddModal({
            ...familyAddModal,
            visible: false,
          })
        }}
      />}
      {memberViewModal && <MemberViewModal
        key={memberViewModal.key}
        visible={memberViewModal.visible}
        familyId={memberViewModal.familyId}
        onOk={() => {
          setMemberViewModal({
            ...memberViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setMemberViewModal({
            ...memberViewModal,
            visible: false,
          })
        }}
      />}
      {familyServiceViewModal && <FamilyServiceViewModal
        key={familyServiceViewModal.key}
        visible={familyServiceViewModal.visible}
        familyId={familyServiceViewModal.familyId}
        onOk={() => {
          setFamilyServiceViewModal({
            ...familyServiceViewModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setFamilyServiceViewModal({
            ...familyServiceViewModal,
            visible: false,
          })
        }}
      />}
      {familyImportModal && <FamilyImportModal
        key={familyImportModal.key}
        visible={familyImportModal.visible}
        onOk={() => {
          setFamilyImportModal({
            ...familyImportModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setFamilyImportModal({
            ...familyImportModal,
            visible: false,
          })
        }}
      />}
      {qrCodeViewModal && <QrCodeViewModal
          key={qrCodeViewModal.key}
          visible={qrCodeViewModal.visible}
          familyId={qrCodeViewModal.familyId}
          familyNumber={qrCodeViewModal.familyNumber}
          onCancel={() => {
            setQrcodeViewModal({
              ...qrCodeViewModal,
              visible: false,
            })
          }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};
