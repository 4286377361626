import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment';

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  InputNumber,
  DatePicker, Radio, Divider,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function MemberEditModal (props) {
  const metadata = useSelector(state => state.metadata.value);
  const {memberId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [member, setMember] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const memberRes = await ToolRequest.request('GET', `/member/${memberId}`)
        if(memberRes.data.birthDate){
          memberRes.data.birthDate = moment(memberRes.data.birthDate).utcOffset(metadata.utcOffset)
        }
        if(memberRes.data.yearOfResidenceInHk){
          memberRes.data.yearOfResidenceInHk = moment(memberRes.data.yearOfResidenceInHk).utcOffset(metadata.utcOffset)
        }
        if(memberRes.data){
          const familyRes = await await ToolRequest.request('GET', `/family/${memberRes.data.familyId}`)
          if(familyRes.data.mainMemberId === memberRes.data.id){
            memberRes.data.isMain = true
          }else {
            memberRes.data.isMain = false
          }
        }
        setMember({
          ...memberRes.data
        })
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      let birthDate = null
      if(values.birthDate){
        birthDate = moment(values.birthDate).utcOffset(metadata.utcOffset).format('YYYY-MM-DD')
      }
      let yearOfResidenceInHk = null
      if(values.yearOfResidenceInHk){
        yearOfResidenceInHk = moment(values.yearOfResidenceInHk).utcOffset(metadata.utcOffset).format('YYYY-MM-DD')
      }
      const data = {
        chineseFullName: values.chineseFullName,
        firstNameEn: values.firstNameEn,
        lastNameEn: values.lastNameEn,
        idNumber: values.idNumber,
        isMain: values.isMain,
        birthDate,
        email: values.email,
        phone: values.phone,
        remark: values.remark,
        gender: values.gender,
        maritalStatus: values.maritalStatus,
        occupation: values.occupation,
        yearOfResidenceInHk,
        relation: values.relation,
      }
      await ToolRequest.request('PUT', `/member/${memberId}`, data)
      message.success(I18n.t('editSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          case 'INVALID_MEMBER_PHONE': {
            return message.error(I18n.t('請填寫申請人電話'))
          }
          case 'DUPLICATE_USER_PHONE': {
            return message.error(I18n.t('申請人電話重覆'))
          }
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('編輯家庭成員')}
      open={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={member}
            onFinish={onFinish}
          >
            <Form.Item
              label={I18n.t('英文名字')}
              name="firstNameEn"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('英文名字'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('英文名字')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('英文姓氏')}
              name="lastNameEn"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('英文姓氏'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('英文姓氏')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('身份證號')}
              name="idNumber"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('身份證號'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('身份證號')}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              label={I18n.t('中文姓名')}
              name="chineseFullName"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: I18n.t('中文姓名'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('中文姓名')}
              />
            </Form.Item>
            <Form.Item
              label="性別"
              name="gender"
            >
              <Radio.Group>
                <Radio value="FEMALE">女性</Radio>
                <Radio value="MALE">男性</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="婚姻状况"
              name="maritalStatus"
            >
              <Radio.Group>
                <Radio value="MARRIED">已婚</Radio>
                <Radio value="UNMARRIED">未婚</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={I18n.t('生日')}
              name="birthDate"
            >
              <DatePicker
                placeholder={I18n.t('生日')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('居港年份')}
              name="yearOfResidenceInHk"
            >
              <DatePicker
                picker="year"
                placeholder={I18n.t('居港年份')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('同申請人關係')}
              name="relation"
            >
              <Input
                placeholder={I18n.t('同申請人關係')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('電話號碼')}
              name="phone"
            >
              <Input
                placeholder={I18n.t('電話號碼')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('郵箱')}
              name="email"
            >
              <Input type="email" placeholder={I18n.t('郵箱')}/>
            </Form.Item>

            <Form.Item
              label={I18n.t('職業')}
              name="occupation"
            >
              <Input
                placeholder={I18n.t('職業')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};